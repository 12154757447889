export const GETCERTIFICATE = "GETCERTIFICATE";
export const GETCERTIFICATESUCCESS="GETCERTIFICATESUCCESS";
export const GETCERTIFICATEFAILURE="GETCERTIFICATEFAILURE";

export const CREATECERTIFICATE = "CREATECERTIFICATE";
export const CREATECERTIFICATESUCCESS="CREATECERTIFICATESUCCESS";
export const CREATECERTIFICATEFAILURE="CREATECERTIFICATEFAILURE";

export const DELETECREATECERTIFICATE = "DELETECREATECERTIFICATE";
export const DELETECREATECERTIFICATESUCCESS="DELETECREATECERTIFICATESUCCESS";
export const DELETECREATECERTIFICATEFAILURE="DELETECREATECERTIFICATEFAILURE";