export const RECURIT_LOGIN = "RECURIT_LOGIN";
export const RECURIT_LOGIN_SUCCESS = "RECURIT_LOGIN_SUCCESS";
export const RECURIT_LOGIN_FAIL = "RECURIT_LOGIN_FAIL";


export const RECURIT_OTP_VERIFY = "RECURIT_OTP_VERIFY";
export const RECURIT_OTP_VERIFY_SUCCESS = "RECURIT_OTP_VERIFY_SUCCESS";
export const RECURIT_OTP_VERIFY_FAIL = "RECURIT_OTP_VERIFY_FAIL";

export const RESET_LOGIN = "RESET_LOGIN";
export const RESET_OTP = "RESET_OTP";
export const REMOVE_LOGIN_ID = "REMOVE_LOGIN_ID";

