import { GETCERTIFICATE,GETCERTIFICATESUCCESS,GETCERTIFICATEFAILURE,
    CREATECERTIFICATE,CREATECERTIFICATESUCCESS,CREATECERTIFICATEFAILURE,
    DELETECREATECERTIFICATE,DELETECREATECERTIFICATESUCCESS,DELETECREATECERTIFICATEFAILURE
} from "./actiontype";

const initalValues = {
    getCertificateresult: {},
    SaveCertificate:{},
    deleteCertificeResponse:{},
    loading: false,

    error: {
        message: "",
    },
}

export const CertificateReducers = (state = initalValues, action) => {
  
    if (!action) return state;
    switch (action.type) {
        case GETCERTIFICATE:
            state = { ...state,   loading: true };
            break;
        case GETCERTIFICATESUCCESS:
            state = { ...state, getCertificateresult: action.payload, loading: false };
            break;
        case GETCERTIFICATEFAILURE:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;
        case CREATECERTIFICATE:
            state = { ...state, SaveCertificate: {}, loading: true };
            break;
        case CREATECERTIFICATESUCCESS:
            state = { ...state, SaveCertificate: action.payload, loading: false };
            break;
        case CREATECERTIFICATEFAILURE:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;
        case DELETECREATECERTIFICATE:
            state = { ...state, deleteCertificeResponse: {}, loading: true };
            break;
        case DELETECREATECERTIFICATESUCCESS:
            state = { ...state, deleteCertificeResponse: action.payload, loading: false };
            break;
        case DELETECREATECERTIFICATEFAILURE:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;
        default:
            state = { ...state };
            break
    }
    return state;
}

export default CertificateReducers;