import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from "react-ga4";
import { HelmetProvider } from 'react-helmet-async';
import { Auth0Provider } from '@auth0/auth0-react';
import { GoogleOAuthProvider } from '@react-oauth/google';



ReactGA.initialize("G-NTJHTPLDG8");
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
  title: "Near By Freelancer"
});

// Create the root element for React 18
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <GoogleOAuthProvider
    // domain="dev-nsqek6ix77yglj1v.us.auth0.com"
    clientId="1010031458079-e3cn0noniskeanhmunc75n8iqpu2m0kp.apps.googleusercontent.com"
    // authorizationParams={{
    //   redirect_uri: window.location.origin
    // }}
  >
    <App />
  </GoogleOAuthProvider>,
  // <Auth0Provider
  //   domain="dev-nsqek6ix77yglj1v.us.auth0.com"
  //   clientId="cC6y485tqlvjJTk194JB9ThKWi3hUb7E"
  //   authorizationParams={{
  //     redirect_uri: window.location.origin
  //   }}
  // >
  //   <App />
  // </Auth0Provider>,

);
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   // <React.StrictMode>
//     <App />
//   // {/* </React.StrictMode> */}
// );

// If you want to start measuring performance in your app, pass a function

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
