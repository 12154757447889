export const BESTHOST = "/popular-host";
export const LOGINREGISTRATION = "/otp-send";
export const OTPVERIFY = "/otp-verify";
export const GETALLREGISTRATIONDATA = "/clientdata";
export const SaveProfesionalContent = "/client";
export const GetEducation = "/educations";
export const SaveEducation = "/educations";
export const DeleteEducation = "/educations";
export const Certificate = "/certifications";
export const PORTFOLIO = "/portfolios";
export const  RECURITLOGIN = "/otpsend";
export const RECURITVERIFY = "/otpverify";
export const RECUITER = "/recuiter";
export const FILTER = "/clients";
export const GETALLRECUITER = "/recuiter/all";
export const POSTFREELACERURL = "/recruiter-booking-freelancer"
export const POSTRECUITERURL = "/freelancer-booking-recuiter"