import { SAVE_PORTFOLIO, SAVE_PORTFOLIO_SUCCESS, SAVE_PORTFOLIO_FAIL,
    DELETE_PORTFOLIO,DELETE_PORTFOLIO_SUCCESS,DELETE_PORTFOLIO_FAIL

} from "./actiontype";

export const savePortFolio = (payload) => {
    return {
        type: SAVE_PORTFOLIO,
        payload: payload
    }

}
export const savePortFolioSuccess = (response) => {
    return {
        type: SAVE_PORTFOLIO_SUCCESS,
        payload: response
    }
}
export const savePortFolioFailure = (error) => {
    return {
        type: SAVE_PORTFOLIO_FAIL,
        payload: error
    }
}
export const deletePortFolio = (CLIENT_ID,PORTFOLIO_ID) => {
    return {
        type: DELETE_PORTFOLIO,
        payload: {CLIENT_ID,PORTFOLIO_ID}
    }

}
export const deleteFolioSuccess = (response) => {
    return {
        type: DELETE_PORTFOLIO_SUCCESS,
        payload: response
    }
}
export const deleteFolioFailure = (error) => {
    return {
        type: DELETE_PORTFOLIO_FAIL,
        payload: error
    }
}
