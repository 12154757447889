import { SAVE_PORTFOLIO, DELETE_PORTFOLIO } from "./actiontype";
import { all, takeLatest, put, call } from 'redux-saga/effects';
import {
    deleteFolioSuccess,
    deleteFolioFailure,
    savePortFolioSuccess,
    savePortFolioFailure
} from "./action";
import { savePortFolioApi,deletePortfolioApi } from "../../_helper/services/portfolioregestration";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* onSavePortfolio({ payload: { clientId, payload } }) {

    try {
        const response = yield call(savePortFolioApi, { clientId, payload });
        yield put(savePortFolioSuccess(response));
        toast.success('PortFolio Added  SuccessFully Added', {
            position: 'top-left'
        });
    } catch (error) {
        yield put(savePortFolioFailure(error.response));
    }
}
function* OnDeletePortFolio({ payload: { CLIENT_ID, PORTFOLIO_ID } }) {
     
    try {
        const confirmed = window.confirm("Are you sure you want to delete?");
        if (confirmed) {
            const response = yield call(deletePortfolioApi, { CLIENT_ID, PORTFOLIO_ID });
            yield put(deleteFolioSuccess(response));
            toast.success('Delete  SuccessFully ', {
                position: 'top-left'
            });
        }

    } catch (error) {
        yield put(deleteFolioFailure(error.response));
    }

}
<ToastContainer />


function* CartSaga() {
    yield takeLatest(SAVE_PORTFOLIO, onSavePortfolio);
    yield takeLatest(DELETE_PORTFOLIO, OnDeletePortFolio);


}

export default CartSaga;