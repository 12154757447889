import { RECURIT_LOGIN,RECURIT_LOGIN_SUCCESS,RECURIT_LOGIN_FAIL,
    RECURIT_OTP_VERIFY,RECURIT_OTP_VERIFY_SUCCESS,RECURIT_OTP_VERIFY_FAIL,
    REMOVE_LOGIN_ID
} from "./actiontype"


export const createRecuritlogin=(payload)=>{
    return{
        type:RECURIT_LOGIN,
        payload:payload
    }
}
export const createloginRecuritSuccess=(response)=>{
    return{
        type:RECURIT_LOGIN_SUCCESS,
        payload:response
    }
}
export const createloginRecuritFail=(error)=>{
    return{
        type:RECURIT_LOGIN_FAIL,
        payload:error
    }
}
export const verifyRecuritOtp=(payload)=>{
    return{
        type:RECURIT_OTP_VERIFY,
        payload:payload
    }
}
export const verifyOtpRecuritSuccess=(response)=>{
    return{
        type:RECURIT_OTP_VERIFY_SUCCESS,
        payload:response
    }
}
export const verifyOtpRecuritFail=(error)=>{
    return{
        type:RECURIT_OTP_VERIFY_FAIL,
        payload:error
    }
}
export const removeLoginId = () => ({
    type: REMOVE_LOGIN_ID,
  });
// export const resetlogin=(payload)=>{
//     return{
//         type:RESET_LOGIN,
//         payload:payload
//     }
// }
// export const resetotp=(payload)=>{
//     return{
//         type:RESET_OTP,
//         payload:payload
//     }
// }