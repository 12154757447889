import { GET_ALLDETAILS_REGISTRATION, SAVE_PERSONALDETAIL_CONTENT, RESET } from "./actiontype";
import { all, takeLatest, put, call } from 'redux-saga/effects';
import {
  getalldataregistrationSuccess,
  getalldataregistrationFail, savepersonalDetailSuccess, savepersonalDetailFail,
  reset
} from "./action";
import { getallregistrationProfileDataapi, savepersonalDetial } from "./../../_helper/services/registrationfreelancer"
import { delay } from "redux-saga/effects";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* onGetAllDetailRegisProfile({ payload: clientId }) {

  try {
    const response = yield call(getallregistrationProfileDataapi, clientId);
    yield put(getalldataregistrationSuccess(response));

  } catch (error) {
    yield put(getalldataregistrationFail(error.response));
  }
}
function* onSavePersonaDetail({ payload: { clientId, payload } }) {
  try {
    const response = yield call(savepersonalDetial, { clientId, payload });
    yield put(savepersonalDetailSuccess(response));
    toast.success(response?.message, {
      position: 'top-left',

    });
  } catch (error) {
    yield put(savepersonalDetailFail(error.response));
  }
  // yield delay(3000);

  // yield put(reset());  

}


function* RegistratinSaga() {
  yield takeLatest(GET_ALLDETAILS_REGISTRATION, onGetAllDetailRegisProfile);
  yield takeLatest(SAVE_PERSONALDETAIL_CONTENT, onSavePersonaDetail);
  yield takeLatest(RESET, reset);


}
<ToastContainer />
export default RegistratinSaga;