import { CREATE_LOGIN, OTP_VERIFY, RESET_LOGIN, RESET_OTP,REMOVE_RECRUITER_ID  } from "./actiontype";
import { all, takeLatest, put, call } from 'redux-saga/effects';
import {
  createloginSuccess,
  createloginFail, verifyOtpSuccess, verifyOtpFail,
  resetlogin, resetotp,
  removeRecruiterId
 
} from "./action";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createloginregistration, otpverifyapi,regisprofileapi } from "../../_helper/services/loginregeristration";

function* onCreateLogin({ payload }) {
  try {
    const response = yield call(createloginregistration, payload);
    yield put(createloginSuccess(response));
   
    
    toast.success(response?.message, {
      position: 'top-right',

    });
  } catch (error) {
    yield put(createloginFail(error.response));
    toast.error('Please Provide Correct Mobile Number.', {
      position: 'top-right',
    });
  }
  //  finally{
  //   yield put(resetlogin())
  // }
}
function* onVerifyOtp({ payload }) {
  try {
    const response = yield call(otpverifyapi, payload);
    yield put(verifyOtpSuccess(response));
    localStorage.setItem("client_Id",response?.client?.id);
  } catch (error) {
    yield put(verifyOtpFail(error.response));
     
    toast.error(' OTP is not correct.', {
      position: 'top-right',
    });
  }finally{
    yield put(removeRecruiterId());

    // yield put(resetotp());

  }
}
 
<ToastContainer />



function* CartSaga() {
  yield takeLatest(CREATE_LOGIN, onCreateLogin);
  yield takeLatest(OTP_VERIFY, onVerifyOtp);
 
  yield takeLatest(RESET_LOGIN, resetlogin);
  yield takeLatest(RESET_OTP, resetotp);
  yield takeLatest(RESET_OTP, removeRecruiterId);


}

export default CartSaga;