export const GET_RECUIRIT = "GET_RECUIRIT";
export const GET_RECUIRIT_SUCCESS = "GET_RECUIRIT_SUCCESS";
export const GET_RECURIT_FAIL = "GET_RECURIT_FAIL";

export const SAVE_RECURIT = "SAVE_RECURIT";
export const SAVE_RECURIT_SUCCESS = "SAVE_RECURIT_SUCCESS";
export const SAVE_RECURIT_FAIL = "SAVE_RECURIT_FAIL";

export const DELETE_RECURIT = "DELETE_RECURIT";
export const DELETE_RECURIT_SUCCESS = "DELETE_RECURIT_SUCCESS";
export const DELETE_RECURIT_FAIL = "DELETE_RECURIT_FAIL";

export const RESET = "RESET"