import { GETEDUCATION,GETEDUCATIONSUCCESS,GETEDUCATIONFAILURE,
    CREATEEDUCATION,CREATEEDUCATIONSUCCESS,CREATEEDUCATIONFAILURE,
    DELETEEDUCATION,DELETEEDUCATIONSUCCESS,DELETEEDUCATIONFAILURE
} from "./actiontype";

const initalValues = {
    getEducationresult: {},
    SaveEducation:{},
    deleteEduResponse:{},
    loading: false,

    error: {
        message: "",
    },
}

export const EducationReducers = (state = initalValues, action) => {
  
    if (!action) return state;
    switch (action.type) {
        case GETEDUCATION:
            state = { ...state,   loading: true };
            break;
        case GETEDUCATIONSUCCESS:
            state = { ...state, getEducationresult: action.payload, loading: false };
            break;
        case GETEDUCATIONFAILURE:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;
        case CREATEEDUCATION:
            state = { ...state, SaveEducation: {}, loading: true };
            break;
        case CREATEEDUCATIONSUCCESS:
            state = { ...state, SaveEducation: action.payload, loading: false };
            break;
        case CREATEEDUCATIONFAILURE:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;
        case DELETEEDUCATION:
            state = { ...state, deleteEduResponse: {}, loading: true };
            break;
        case DELETEEDUCATIONSUCCESS:
            state = { ...state, deleteEduResponse : action.payload, loading: false };
            break;
        case DELETEEDUCATIONFAILURE:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;
        default:
            state = { ...state };
            break
    }
    return state;
}

export default EducationReducers;