import {GET_RECUIRIT,GET_RECUIRIT_SUCCESS,GET_RECURIT_FAIL,
    SAVE_RECURIT,SAVE_RECURIT_SUCCESS,SAVE_RECURIT_FAIL,
    DELETE_RECURIT,DELETE_RECURIT_SUCCESS,DELETE_RECURIT_FAIL
    
} from "./actiontype";

export const getallRecuirit=(RECUITER_ID)=>{
  
    return{
        type:GET_RECUIRIT,
        payload:RECUITER_ID
    }
}
export const getallRecuiritSuccess=(data)=>{
    return{
        type:GET_RECUIRIT_SUCCESS,
        payload:data
    }
}
export const getallRecuiritFail=(error)=>{
    return{
        type:GET_RECURIT_FAIL,
        payload:error
    }
}
export const saveRecurit=(payload)=>{
    return{
        type:SAVE_RECURIT,
        payload:payload
    }
}
export const saveRecuiritSuccess=(response)=>{
    return{
        type:SAVE_RECURIT_SUCCESS,
        payload:response
    }
}
export const saveRecuiritFail=(error)=>{
    return{
        type:SAVE_RECURIT_FAIL,
        payload:error
    }
}
export const deleteRecuiter=(RECUITER_ID)=>{
      
    return{
        type:DELETE_RECURIT,
        payload:RECUITER_ID
        
    }
}
export const deleteRecuiterSuccess=(response)=>{
    return{
        type:DELETE_RECURIT_SUCCESS,
        payload:response
    }
}
export const deleteRecuiterFail=(error)=>{
    return{
        type:DELETE_RECURIT_FAIL,
        payload:error
    }
}