import {GET_RECUIRIT,GET_RECUIRIT_SUCCESS,GET_RECURIT_FAIL,
    SAVE_RECURIT,SAVE_RECURIT_SUCCESS,SAVE_RECURIT_FAIL,
    DELETE_RECURIT,DELETE_RECURIT_SUCCESS,DELETE_RECURIT_FAIL
    
} from "./actiontype";

const initalValues = {
    saveRecuiritForm: {},
    getallRecurit:{},
    loading: false,
    deleteRecuResponse:{},


    error: {
        message: "",
    },
}

export const RecuritReducers = (state = initalValues, action) => {
  
    if (!action) return state;
    switch (action.type) {
        case GET_RECUIRIT:
            state = { ...state,   loading: true };
            break;
        case GET_RECUIRIT_SUCCESS:
            state = { ...state, getallRecurit: action.payload, loading: false };
            break;
        case GET_RECURIT_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;
        case SAVE_RECURIT:
            state = { ...state, saveRecuiritForm: {}, loading: true };
            break;
        case SAVE_RECURIT_SUCCESS:
            state = { ...state, saveRecuiritForm: action.payload, loading: false };
            break;
        case SAVE_RECURIT_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;
            case DELETE_RECURIT:
                state = { ...state, deleteRecuResponse: {}, loading: true };
                break;
            case DELETE_RECURIT_SUCCESS:
                state = { ...state, deleteRecuResponse : action.payload, loading: false };
                break;
            case DELETE_RECURIT_FAIL:
                state = {
                    ...state,
                    error: {
                        message: "Error",
                    },
                    loading: false,
                };
                break;
            // case RESET:
            //     state={...state,savepersonalDetailForm:{}}
        default:
            state = { ...state };
            break
    }
    return state;
}

export default RecuritReducers;