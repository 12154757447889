import React, { useState } from 'react'
import Header from '../../Layout/Header/Index'
import Footer from '../../Layout/Footer/Index'
import "./refertoearn.scss";

import { GoogleOAuthProvider, GoogleLogin, googleLogout } from '@react-oauth/google';

const ReferEarn = () => {
   

    const [user, setUser] = useState(null);
    const handleGoogleLoginSuccess = (response) => {
        console.log("checkResponse", response)
        // You can now store the user's info in your state or context
    };

    const handleGoogleLoginFailure = (error) => {
        console.log("Google Login Failed", error);
    };
    const handleLogout = () => {
        googleLogout(); // Log the user out
        setUser(null); // Clear user state
        console.log('User logged out');
        

    };
    return (
        <>
            {/* <Header /> */}

            <GoogleOAuthProvider clientId="1010031458079-e3cn0noniskeanhmunc75n8iqpu2m0kp.apps.googleusercontent.com">
                <>
                    {/* <Header /> */}
                    <div className="refer-to-rearn-container">
                        <section className="refer-to-rearn-container">
                            <div className="refer-to-rearn-flex1">
                                <img src="/image/recuiter-login-banner.png" alt="Recruiter Login Banner" />
                            </div>
                            <div className="refer-to-rearn-flex2">
                                <div className="refer-to-rearn-flex2-container">
                                    <div className="rerfer-earn-logo">
                                        <img src="/image/nbflogo.png" alt="NBF Logo" />
                                    </div>
                                    <h3>
                                        Log in as referrer <span><img src="/image/" alt="" /></span>
                                    </h3>

                                    <form className="">
                                        <div className="refer-earn-input">
                                            {user ? (
                                                <button type="button" onClick={handleLogout} className="logout-button">
                                                    Logout
                                                </button>
                                            ) : (
                                                <GoogleLogin
                                                    className="google-login"
                                                    onSuccess={handleGoogleLoginSuccess}
                                                    onError={handleGoogleLoginFailure}
                                                    useOneTap
                                                    style={{ width: '100%' }}
                                                />
                                            )}
                                        </div>
                                        {!user && (
                                            <>
                                                <div className="refer-earn-input">
                                                    <input type="text" placeholder="Username/Email" required />
                                                </div>
                                                <div className="refer-earn-input">
                                                    <input type="password" placeholder="Password" required />
                                                </div>
                                                <div className="refer-earn-button">
                                                    <button type="submit">Submit</button>
                                                </div>
                                            </>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </section>
                        <div></div>
                    </div>
                    {/* <Footer /> */}
                </>
            </GoogleOAuthProvider>

            {/* <Footer /> */}
        </>
    )
}

export default ReferEarn
