import { GET_RECUIRIT, SAVE_RECURIT,DELETE_RECURIT } from "./actiontype";
import { all, takeLatest, put, call } from 'redux-saga/effects';
import {
    getallRecuiritSuccess,
    getallRecuiritFail, saveRecuiritSuccess, saveRecuiritFail,
    deleteRecuiterSuccess,deleteRecuiterFail,
  reset
} from "./action";
 import { getAllRecuiterApi,saverecuiterapi } from "../../_helper/services/recuritForm";
import { delay } from "redux-saga/effects";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* onGetRecurit({ payload: RECUITER_ID }) {

  try {
    const response = yield call(getAllRecuiterApi, RECUITER_ID);
    yield put(getallRecuiritSuccess(response));

  } catch (error) {
    yield put(getallRecuiritFail(error.response));
  }
}
function* onSaveRecurit({ payload: { recuiterId, payload } }) {
  try {
    const response = yield call(saverecuiterapi, { recuiterId, payload });
    yield put(saveRecuiritSuccess(response));
    toast.success(response?.message, {
      position: 'top-left',

    });
  } catch (error) {
    yield put(saveRecuiritFail(error.response));
  }
  // yield delay(3000);

  // yield put(reset());  

}
function* OnDeleteRecuiter({ payload: { EDUCATION_ID } }) {
     
    try {
      const confirmed = window.confirm("Are you sure you want to delete?");
      if (confirmed) {
        const response = yield call(saverecuiterapi, { EDUCATION_ID });
        yield put(deleteRecuiterSuccess(response));
        toast.success('Delete  SuccessFully  ', {
          position: 'top-left'
        });
      }
  
    } catch (error) {
      yield put(deleteRecuiterFail(error.response));
    }}


function* RegistratinSaga() {
  yield takeLatest(GET_RECUIRIT, onGetRecurit);
  yield takeLatest(SAVE_RECURIT, onSaveRecurit);
  yield takeLatest(DELETE_RECURIT, OnDeleteRecuiter);
//   yield takeLatest(RESET, reset);


}
<ToastContainer />
export default RegistratinSaga;