import React, { useEffect, useState } from 'react'
import Header from '../../../Layout/Header/Index'
import { Link, useParams } from 'react-router-dom'
import { axiosApi } from '../../../_helper/api_helper';
import "./applicantforproject.scss";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import VerifiedIcon from '@mui/icons-material/Verified';
import Footer from '../../../Layout/Footer/Index';
const ApplicantForJob = () => {
    const recuiter_Id = localStorage.getItem("recuiter_Id")

    const { id, name } = useParams();
    const [applicantData, setApplicatData] = useState([]);
    const [citiesData, setCitieData] = useState([]);
    const [industriesData, setIndustriesData] = useState([]);
     

    useEffect(() => {
        if (id) {
            applicantApiById(id);
        } else if (name) {
            applicantApiByName(name);
        }
    }, [id, name]);
    useEffect(() => {
        citiesApi();
        industriesApi()
    }, [])

    const citiesApi = async () => {
        const result = await axiosApi.get('/cities');
        setCitieData(result.data)
    }
    const industriesApi = async () => {
        const result = await axiosApi.get('/industries');
        setIndustriesData(result.data)
    }
    
    const applicantApiById = async (multiJobId) => {
        const response = await axiosApi.get(`/recruitertofreelancerforjob?recruiter_id=${recuiter_Id}&job_id=${multiJobId}`);
        setApplicatData(response?.data);
        
    };

    const applicantApiByName = async (multiJobName) => {
        const response = await axiosApi.get(`/freelancers/recruiter-project?recruiter_id=${recuiter_Id}&multi_job_id=${multiJobName}`);
        setApplicatData(response?.data);
        
    };
    return (
        <>
            <Header />
            <div className="applicant-for-project-heading">
                <div className="applicant-for-project-heading-inner">
                    <h1>Applicants for Job ({applicantData?.length})</h1>

                </div>
            </div>
            <div className="applicantproject-container">
                <div className="applicant-inner">
                    <div className="applicant-filter">
                        <div className="applicant-filter-heading">
                            <h6>Filters</h6>
                            <h6>Clear All</h6>
                        </div>
                        <div className="applicant-filter-location">
                            <h6>Skills</h6>
                            {industriesData.map(city => (
                                <div key={city.id} className="city-checkbox">
                                    <input type="checkbox" id={city.id} name={city.name} />
                                    <label htmlFor={city.id}>{city.name}</label>
                                </div>
                            ))}
                        </div>
                        <div className="applicant-filter-location">
                            <h6>Location</h6>
                            {citiesData.map(city => (
                                <div key={city.id} className="city-checkbox">
                                    <input type="checkbox" id={city.id} name={city.city_name} />
                                    <label htmlFor={city.id}>{city.city_name}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="applicant-card-data">
                        {applicantData.length > 0 ? (
                            <>
                                {applicantData && applicantData?.map((item) => (
                                    <>
                                        <div className="applicant-card">
                                            <div className="applicant-card-inner">
                                                <div className="applicant-profile">
                                                    <div className="applicant-img">
                                                        <img src={`${axiosApi?.defaults?.baseURL}${item?.freelancer_profile_pic}`} alt="" />

                                                    </div>
                                                    <div className="applicant-content">
                                                        <div className="recuiter-freelance-name">
                                                            <h5>{item?.freelancer_name}</h5>
                                                            <span><VerifiedIcon style={{ color: 'rgba(52, 199, 89, 1)' }} /> Verified</span>

                                                        </div>
                                                        <div className="recuiter-freelance-category">
                                                            <h6>{item?.freelancer_occupation_industry}</h6>
                                                        </div>
                                                        <div className="recuiter-freelance-location">
                                                            <p><img src="/svg/location.svg" alt="" /> {item?.freelancer_city}</p>
                                                            <div>
                                                                {item?.['freelancer-gender'] === "MALE" ? (
                                                                    <img src="/svg/male.svg" width='15px' alt="" />
                                                                ) : item?.['freelancer-gender'] === "FEMALE" ? (
                                                                    <img src="/svg/female.svg" width='15px' alt="" />
                                                                ) : (
                                                                    <img src="/svg/transgender.svg" width='15px' alt="" />
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className="applicant-education">
                                                    <div className="applicant-education-name"><img src="/svg/bag.svg" alt="" /> {item?.freelancer_experience_txt || item?.freelancer_experience}</div>
                                                    <div className="applicant-education-name"><img src="/svg/rupee.svg" alt="" /> {item?.freelancer_project_price}</div>
                                                </div>
                                                <div className="applicant-button">
                                                    <button><Link to={`/Freelancer/${item?.freelancer_id}`}> View Profile</Link></button>
                                                    <button>Interested </button>
                                                    <ThumbUpOffAltIcon />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}

                            </>
                        ) : (
                            <div>No Data Found</div>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default ApplicantForJob
