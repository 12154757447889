import { CREATE_LOGIN,CREATE_LOGIN_SUCCESS,CREATE_LOGIN_FAIL,
    OTP_VERIFY,OTP_VERIFY_SUCCESS,OTP_VERIFY_FAIL,
    RESET_LOGIN,RESET_OTP,
    REMOVE_RECRUITER_ID 
 

} from "./actiontype";

export const createlogin=(payload)=>{
    return{
        type:CREATE_LOGIN,
        payload:payload
    }
}
export const createloginSuccess=(response)=>{
    return{
        type:CREATE_LOGIN_SUCCESS,
        payload:response
    }
}
export const createloginFail=(error)=>{
    return{
        type:CREATE_LOGIN_FAIL,
        payload:error
    }
}
export const verifyOtp=(payload)=>{
    return{
        type:OTP_VERIFY,
        payload:payload
    }
}
export const verifyOtpSuccess=(response)=>{
    return{
        type:OTP_VERIFY_SUCCESS,
        payload:response
    }
}
export const verifyOtpFail=(error)=>{
    return{
        type:OTP_VERIFY_FAIL,
        payload:error
    }
}
 
export const resetlogin=(payload)=>{
    return{
        type:RESET_LOGIN,
        payload:payload
    }
}
export const resetotp=(payload)=>{
    return{
        type:RESET_OTP,
        payload:payload
    }
}
export const removeRecruiterId = () => ({
    type: REMOVE_RECRUITER_ID,
  });