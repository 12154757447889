export const SAVE_PORTFOLIO = "SAVE_PORTFOLIO";
export const SAVE_PORTFOLIO_SUCCESS = "SAVE_PORTFOLIO_SUCCESS";
export const SAVE_PORTFOLIO_FAIL = "SAVE_PORTFOLIO_FAIL";

 

export const DELETE_PORTFOLIO = "DELETE_PORTFOLIO";
export const DELETE_PORTFOLIO_SUCCESS = "DELETE_PORTFOLIO_SUCCESS";
export const DELETE_PORTFOLIO_FAIL = "DELETE_PORTFOLIO_FAIL";


