import { GET_ALLDETAILS_REGISTRATION,GET_ALLDETAILS_REGISTRATION_SUCCESS,GET_ALLDETAILS_REGISTRATION_FAIL,
    SAVE_PERSONALDETAIL_CONTENT,SAVE_PERSONALDETAIL_CONTENT_SUCCESS,SAVE_PERSONALDETAIL_CONTENT_FAIL,
    RESET

} from "./actiontype";

export const getalldataregistration=(clientId)=>{
  
    return{
        type:GET_ALLDETAILS_REGISTRATION,
        payload:clientId
    }
}
export const getalldataregistrationSuccess=(data)=>{
    return{
        type:GET_ALLDETAILS_REGISTRATION_SUCCESS,
        payload:data
    }
}
export const getalldataregistrationFail=(error)=>{
    return{
        type:GET_ALLDETAILS_REGISTRATION_FAIL,
        payload:error
    }
}
export const savepersonalDetail=(payload)=>{
    return{
        type:SAVE_PERSONALDETAIL_CONTENT,
        payload:payload
    }
}
export const savepersonalDetailSuccess=(response)=>{
    return{
        type:SAVE_PERSONALDETAIL_CONTENT_SUCCESS,
        payload:response
    }
}
export const savepersonalDetailFail=(error)=>{
    return{
        type:SAVE_PERSONALDETAIL_CONTENT_FAIL,
        payload:error
    }
}
export const reset=(payload)=>{
    return{
        type:RESET,
        payload:payload
    }
}