import { GETFILTERDATA, GETFILTERRECUITER,GETALLRECUITER,RESET } from "./actiontype";
import { all, takeLatest, put, call } from 'redux-saga/effects';
import {
    getFilterDataSuccess,
    getFilterDataFail,
    getFilterRecuiterSuccess,
    getFilterRecuiterFail,
    getAllRecuiterSuccess,getAllRecuiterFail,
    resetFreelancerData
     
} from "./action";
import { getFilterApi, getFilterRecuiterApi,getAllRecuiter } from "../../_helper/services/filterpublic";

function* onGetFilterData({ payload: { state, Occupation_industry, Occupation_skills } }) {

    try {
        const response = yield call(getFilterApi, { state, Occupation_industry, Occupation_skills });
        yield put(getFilterDataSuccess(response));
    } catch (error) {
        yield put(getFilterDataFail(error.response));
    }
    // finally{
        
    
    //     yield put(resetFreelancerData());
    
    //   }
}
function* onGetFilterRecuiter({ payload: { state, industry, skill } }) {

    try {
        const response = yield call(getFilterRecuiterApi, { state, industry, skill });
        yield put(getFilterRecuiterSuccess(response));
    } catch (error) {
        yield put(getFilterRecuiterFail(error.response));
        
    }
}
function* onAllRecuiter({ payload  }) {

    try {
        const response = yield call(getAllRecuiter, payload);
        yield put(getAllRecuiterSuccess(response));
    } catch (error) {
        yield put(getAllRecuiterFail(error.response));
    }
}
 




function* FilterSaga() {
    yield takeLatest(GETFILTERDATA, onGetFilterData);
    yield takeLatest(GETFILTERRECUITER, onGetFilterRecuiter);
    yield takeLatest(GETALLRECUITER, onAllRecuiter);
//   yield takeLatest(RESET, resetFreelancerData);
    



}

export default FilterSaga;