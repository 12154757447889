import { GETCERTIFICATE, CREATECERTIFICATE, DELETECREATECERTIFICATE } from "./actiontype";
import { all, takeLatest, put, call } from 'redux-saga/effects';
import {
    getCertificateSuccess,
    getCertificateFail, createCertficateSuccess, createCertficateFail,
    deleteCertificateSuccess, deleteCertificateFail
} from "./action";
import { certificationApi, getCetificationapi, deleteCertificateApi } from "../../_helper/services/certificateregistration";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* onGetEducation({ payload: clientId }) {

    try {
        const response = yield call(getCetificationapi, clientId);
        yield put(getCertificateSuccess(response));
    } catch (error) {
        yield put(getCertificateFail(error.response));
    }
}
function* onSaveCertificate({ payload: { clientId, payload } }) {

    try {
        const response = yield call(certificationApi, { clientId, payload });
        yield put(createCertficateSuccess(response));
        toast.success('Certification Added  SuccessFully ', {
            position: 'top-left'
        });
    } catch (error) {
        yield put(createCertficateFail(error.response));
    }
}
function* OnDeleteCertificate({ payload: { CLIENT_ID, CERTIFICATION_ID } }) {

    try {
        const confirmed = window.confirm("Are you sure you want to delete?");
        if (confirmed) {
            const response = yield call(deleteCertificateApi, { CLIENT_ID, CERTIFICATION_ID });
            yield put(deleteCertificateSuccess(response));
            toast.success('Delete  SuccessFully  ', {
                position: 'top-left'
            });
        }

    } catch (error) {
        yield put(deleteCertificateFail(error.response));
    }
    // finally {
    //   yield put(resetDeleteMenu());  
    // }
}


function* CertificateSaga() {
    yield takeLatest(GETCERTIFICATE, onGetEducation);
    yield takeLatest(CREATECERTIFICATE, onSaveCertificate);
    yield takeLatest(DELETECREATECERTIFICATE, OnDeleteCertificate);


}
<ToastContainer />

export default CertificateSaga;