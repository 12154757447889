import {
    CREATE_LOGIN, CREATE_LOGIN_SUCCESS, CREATE_LOGIN_FAIL,
    OTP_VERIFY, OTP_VERIFY_SUCCESS, OTP_VERIFY_FAIL,

    RESET_LOGIN, RESET_OTP,
    REMOVE_RECRUITER_ID
} from "./actiontype";

const initalValues = {
    login: {},
    verifyOtpresult: {},
    loading: false,
    savepersonalDetail: {},

    error: {
        message: "",
    },
}

export const LoginReducers = (state = initalValues, action) => {
    if (!action) return state;
    switch (action.type) {
        case CREATE_LOGIN:
            state = { ...state, login: {}, loading: true };
            break;
        case CREATE_LOGIN_SUCCESS:
            state = { ...state, login: action.payload, loading: false };
            break;
        case CREATE_LOGIN_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;
        case OTP_VERIFY:
            state = { ...state,   loading: true };
            break;
        case OTP_VERIFY_SUCCESS:
            state = { ...state, verifyOtpresult: action.payload, loading: false };
            break;
        case OTP_VERIFY_FAIL:
             
            state = {
                ...state,
                verifyOtpresult:{},
                error: {
                    message: "Error",   
                },
                loading: false,
            };
            break;

        case RESET_LOGIN:
            state = { ...state, login: {}, };
            break;
        case RESET_OTP:
            state = { ...state, verifyOtpresult: {}, };
            break;
        case REMOVE_RECRUITER_ID:
            localStorage.removeItem("recuiter_Id");
            break;
        default:
            state = { ...state };
            break
    }
    return state;
}

export default LoginReducers;