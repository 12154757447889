import {
    SAVE_PORTFOLIO,
    SAVE_PORTFOLIO_SUCCESS,
    SAVE_PORTFOLIO_FAIL,
    DELETE_PORTFOLIO,DELETE_PORTFOLIO_SUCCESS,DELETE_PORTFOLIO_FAIL
} from "./actiontype";

const initalValues = {
    saveportfolioResults: {},
    deletePortfolioresutls :{},
    loading: false,


    error: {
        message: "",
    },
}

export const PortFolioReducers = (state = initalValues, action) => {

    if (!action) return state;
    switch (action.type) {
        case SAVE_PORTFOLIO:
            state = { ...state, loading: true };
            break;
        case SAVE_PORTFOLIO_SUCCESS:
            state = { ...state, saveportfolioResults: action.payload, loading: false };
            break;
        case SAVE_PORTFOLIO_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;
        case DELETE_PORTFOLIO:
            state = { ...state,deletePortfolioresutls:{}, loading: true };
            break;
        case DELETE_PORTFOLIO_SUCCESS:
            state = { ...state, deletePortfolioresutls: action.payload, loading: false };
            break;
        case DELETE_PORTFOLIO_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;
        
        default:
            state = { ...state };
            break
    }
    return state;
}

export default PortFolioReducers;