import {  GET_ALLDETAILS_REGISTRATION,GET_ALLDETAILS_REGISTRATION_SUCCESS,GET_ALLDETAILS_REGISTRATION_FAIL,
    SAVE_PERSONALDETAIL_CONTENT,SAVE_PERSONALDETAIL_CONTENT_SUCCESS,SAVE_PERSONALDETAIL_CONTENT_FAIL,
    RESET
} from "./actiontype";

const initalValues = {
    savepersonalDetailForm: {},
    getallRegistrationDetail:{},
    loading: false,


    error: {
        message: "",
    },
}

export const ProfileRegistrationReducers = (state = initalValues, action) => {
  
    if (!action) return state;
    switch (action.type) {
        case GET_ALLDETAILS_REGISTRATION:
            state = { ...state,   loading: true };
            break;
        case GET_ALLDETAILS_REGISTRATION_SUCCESS:
            state = { ...state, getallRegistrationDetail: action.payload, loading: false };
            break;
        case GET_ALLDETAILS_REGISTRATION_FAIL:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;
        case SAVE_PERSONALDETAIL_CONTENT:
            state = { ...state, savepersonalDetailForm: {}, loading: true };
            break;
        case SAVE_PERSONALDETAIL_CONTENT_SUCCESS:
            state = { ...state, savepersonalDetailForm: action.payload, loading: false };
            break;
        case SAVE_PERSONALDETAIL_CONTENT_FAIL:
            state = {
                ...state,
                savepersonalDetailForm:{},
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;
            case RESET:
                state={...state,savepersonalDetailForm:{}}
        default:
            state = { ...state };
            break
    }
    return state;
}

export default ProfileRegistrationReducers;