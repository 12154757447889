import { GETCERTIFICATE,GETCERTIFICATESUCCESS,GETCERTIFICATEFAILURE,
    CREATECERTIFICATE,CREATECERTIFICATESUCCESS,CREATECERTIFICATEFAILURE,
    DELETECREATECERTIFICATE,DELETECREATECERTIFICATESUCCESS,DELETECREATECERTIFICATEFAILURE
} from "./actiontype";


export const getCertificate=(clientId)=>{
  
    return{
        type:GETCERTIFICATE,
        payload:clientId
    }
}
export const getCertificateSuccess=(data)=>{
    return{
        type:GETCERTIFICATESUCCESS,
        payload:data
    }
}
export const getCertificateFail=(error)=>{
    return{
        type:GETCERTIFICATEFAILURE,
        payload:error
    }
}
export const createCertficate=(payload)=>{
    return{
        type:CREATECERTIFICATE,
        payload:payload
    }
}
export const createCertficateSuccess=(response)=>{
    return{
        type:CREATECERTIFICATESUCCESS,
        payload:response
    }
}
export const createCertficateFail=(error)=>{
    return{
        type:CREATECERTIFICATEFAILURE,
        payload:error
    }
}
export const deleteCertificate=(CLIENT_ID,CERTIFICATION_ID)=>{
    return{
        type:DELETECREATECERTIFICATE,
        payload:{CLIENT_ID,CERTIFICATION_ID}
    }
}
export const deleteCertificateSuccess=(response)=>{
    return{
        type:DELETECREATECERTIFICATESUCCESS,
        payload:response
    }
}
export const deleteCertificateFail=(error)=>{
    return{
        type:DELETECREATECERTIFICATEFAILURE,
        payload:error
    }
}