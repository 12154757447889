import { POSTFREELACER,POSTFREELACERSUCCESS,POSTFREELACERFAILURE,
    POSTRECUITER,POSTRECUITER_SUCCESS,POSTRECUITERFAILURE
} from "./actiontype";

export const createBookingFreelacer=(payload)=>{
    return{
        type:POSTFREELACER,
        payload:payload
    }
}
export const createBookingFreelacerSuccess=(response)=>{
    return{
        type:POSTFREELACERSUCCESS,
        payload:response
    }
}
export const createBookingFreelacerFailure=(error)=>{
    return{
        type:POSTFREELACERFAILURE,
        payload:error
    }
}
export const createBookingRecuiter=(payload)=>{
    return{
        type:POSTRECUITER,
        payload:payload
    }
}
export const createBookingRecuiterSuccess=(response)=>{
    return{
        type:POSTRECUITER_SUCCESS,
        payload:response
    }
}
export const createBookingRecuiterFailure=(error)=>{
    return{
        type:POSTRECUITERFAILURE,
        payload:error
    }
}