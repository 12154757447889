import { post,get,put, remove } from "../api_helper";
 import {  Certificate } from "../url_helper";

 
 
 
 export const getCetificationapi =(clientId)=> get(`${Certificate}/${clientId}`);
 export const certificationApi =({payload,clientId})=> post(`${Certificate}/${clientId}`,payload);
 export const deleteCertificateApi =({ CLIENT_ID,CERTIFICATION_ID})=> remove(`${Certificate}/${CLIENT_ID}/${CERTIFICATION_ID}`);

 