import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const RoleBasedRoute = ({ allowedRoles }) => {
  const recuiterId = localStorage.getItem("recuiter_Id");
  const clientId = localStorage.getItem("client_Id");

  const userRole = recuiterId ? 'recruiter' : clientId ? 'client' : null;

  if (!userRole || !allowedRoles.includes(userRole)) {
    return <Navigate to="/404" />;
  }

  return <Outlet />;
};

const PublicRoute = () => {
  const recuiterId = localStorage.getItem("recuiter_Id");
  const clientId = localStorage.getItem("client_Id");

  if (recuiterId || clientId) {
    return <Navigate to="/404" />;
  }

  return <Outlet />;
};

export { RoleBasedRoute, PublicRoute };
