import { GETFILTERDATA,GETFILTERDATASUCCESS,GETFILTERDATAFAILURE,
    GETFILTERRECUITER,GETFILTERRECUITERSUCCESS,GETFILTERRECUITERFAILURE,
    GETALLRECUITER,GETALLRECUITERSUCCESS,GETALLRECUITERFAILURE,RESET

} from "./actiontype";


const initalValues = {
    getAllFilterresult: [],
     getFilterRecuiterDataresults:[],
     getAllRecuiterData:[],
 
    loading: false,
    

    error: {
        message: "",
    },
}

export const FilterReducers = (state = initalValues, action) => {
  
    if (!action) return state;
    switch (action.type) {
        case GETFILTERDATA:
            state = { ...state,   loading: true };
            break;
        case GETFILTERDATASUCCESS:
            state = { ...state, getAllFilterresult: action.payload, loading: false };
            break;
        case GETFILTERDATAFAILURE:
            state = {
                ...state,
                getAllFilterresult:[],
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;
        case GETFILTERRECUITER:
            state = { ...state,   loading: true };
            break;
        case GETFILTERRECUITERSUCCESS:
            state = { ...state, getFilterRecuiterDataresults: action.payload, loading: false };
            break;
        case GETFILTERRECUITERFAILURE:
            
            state = {
                ...state,
                getFilterRecuiterDataresults:[],
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;
        case GETALLRECUITER:
            state = { ...state,   loading: true };
            break;
        case GETALLRECUITERSUCCESS:
            state = { ...state, getAllRecuiterData: action.payload, loading: false };
            break;
        case GETALLRECUITERFAILURE:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;
            case RESET:
                state={
                    ...state,
                    getAllFilterresult:{}  
                }
        
        default:
            state = { ...state };
            break
    }
    return state;
}

export default FilterReducers;