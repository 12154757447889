import { GETEDUCATION,GETEDUCATIONSUCCESS,GETEDUCATIONFAILURE,
    CREATEEDUCATION,CREATEEDUCATIONSUCCESS,CREATEEDUCATIONFAILURE,
    DELETEEDUCATION,DELETEEDUCATIONSUCCESS,DELETEEDUCATIONFAILURE
} from "./actiontype";


export const getEducation=(clientId)=>{
  
    return{
        type:GETEDUCATION,
        payload:clientId
    }
}
export const getEducationSuccess=(data)=>{
    return{
        type:GETEDUCATIONSUCCESS,
        payload:data
    }
}
export const getEducationFail=(error)=>{
    return{
        type:GETEDUCATIONFAILURE,
        payload:error
    }
}
export const createEducation=(payload)=>{
    return{
        type:CREATEEDUCATION,
        payload:payload
    }
}
export const createEducationSuccess=(response)=>{
    return{
        type:CREATEEDUCATIONSUCCESS,
        payload:response
    }
}
export const createEducationFail=(error)=>{
    return{
        type:CREATEEDUCATIONFAILURE,
        payload:error
    }
}
export const deleteEducation=(CLIENT_ID,EDUCATION_ID)=>{
      
    return{
        type:DELETEEDUCATION,
        payload:{CLIENT_ID,EDUCATION_ID}
        
    }
}
export const deleteEducationSuccess=(response)=>{
    return{
        type:DELETEEDUCATIONSUCCESS,
        payload:response
    }
}
export const deleteEducationFail=(error)=>{
    return{
        type:DELETEEDUCATIONFAILURE,
        payload:error
    }
}