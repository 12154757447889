import React from 'react'
import "./notfound.scss"
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const NotFound = () => {
    return (
        <>
        <Helmet>
        <title>Page Not Found</title>
      </Helmet>
            <div className="error-page">
                <div className="error-page-container">
                    <div className="error-img-contianer">
                        <img src="/image/errorpage.jpg" alt="" />
                    </div>
                    <div className='error-buttton'>Click<Link to='/'><button className='btn btn-link'>here</button></Link>to return to the NBF. </div>
                </div>
            </div>
        </>
    )
}

export default NotFound