import { RECURIT_LOGIN, RECURIT_OTP_VERIFY, REMOVE_LOGIN_ID } from "./actiontype";
import { all, takeLatest, put, call } from 'redux-saga/effects';
import {
    createloginRecuritSuccess,
    createloginRecuritFail, verifyOtpRecuritSuccess, verifyOtpRecuritFail,
  resetlogin, resetotp,removeLoginId
 
} from "./action";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createloginRecurit,otpverifyRecurit } from "../../_helper/services/recuritlogin"; 

function* onCreateRecuritLogin({ payload }) {
  try {
    const response = yield call(createloginRecurit, payload);
    yield put(createloginRecuritSuccess(response));
   
    
    toast.success(response?.message, {
      position: 'top-right',

    });
  } catch (error) {
    yield put(createloginRecuritFail(error.response));
    toast.error('Please Provide Correct Mobile Number.', {
      position: 'top-right',
    });
  }
  //  finally{
  //   yield put(resetlogin())
  // }
}
function* onVerifyRecuritOtp({ payload }) {
  try {
    const response = yield call(otpverifyRecurit, payload);
    yield put(verifyOtpRecuritSuccess(response));
    localStorage.setItem("recuiter_Id",response?.client?.id);
  } catch (error) {
    yield put(verifyOtpRecuritFail(error.response));
    toast.error(' OTP is not correct.', {
      position: 'top-right',
    });
  }
  finally{
    yield put(removeLoginId())

  }
}
 
<ToastContainer />



function* CartSaga() {
  yield takeLatest(RECURIT_LOGIN, onCreateRecuritLogin);
  yield takeLatest(RECURIT_OTP_VERIFY, onVerifyRecuritOtp);
  yield takeLatest(REMOVE_LOGIN_ID, removeLoginId);
 
//   yield takeLatest(RESET_LOGIN, resetlogin);
//   yield takeLatest(RESET_OTP, resetotp);


}

export default CartSaga;