// this is for freelancer

export const GETFILTERDATA = "GETFILTERDATA";
export const GETFILTERDATASUCCESS = "GETFILTERDATASUCCESS";
export const GETFILTERDATAFAILURE = "GETFILTERDATAFAILURE";

// this is for recuiter
export const GETFILTERRECUITER = "GETFILTERRECUITER ";
export const GETFILTERRECUITERSUCCESS = "GETFILTERRECUITERSUCCESS";
export const GETFILTERRECUITERFAILURE = "GETFILTERRECUITERFAILURE";

export const GETALLRECUITER = "GETALLRECUITER ";
export const GETALLRECUITERSUCCESS = "GETALLRECUITERSUCCESS";
export const GETALLRECUITERFAILURE = "GETALLRECUITERFAILURE";

export const RESET = "RESET";

 







