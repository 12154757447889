export const GETEDUCATION = "GETEDUCATION";
export const GETEDUCATIONSUCCESS="GETEDUCATIONSUCCESS";
export const GETEDUCATIONFAILURE="GETEDUCATIONFAILURE";

export const CREATEEDUCATION = "CREATEEDUCATION";
export const CREATEEDUCATIONSUCCESS="CREATEEDUCATIONSUCCESS";
export const CREATEEDUCATIONFAILURE="CREATEEDUCATIONFAILURE";

export const DELETEEDUCATION = "DELETEEDUCATION";
export const DELETEEDUCATIONSUCCESS="DELETEEDUCATIONSUCCESS";
export const DELETEEDUCATIONFAILURE="DELETEEDUCATIONFAILURE";