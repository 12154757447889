// import      {all,fork} from "redux-saga-effects";
import { all, fork } from "redux-saga/effects"
// import GetDetailSaga from "./besthost/saga";
import LoginRegistration from "./login/saga";
import RegistrationProfileSaga from "./registrationprofilefreelancer/saga";
import EducationRegSaga from "./education/saga";
import CertificateSaga from "./certification/saga";
import PortFolioSaga from "./portfolio/saga";
import RecuritLoginSaga from "./recuritLogin/saga";
import FilterSaga from "./getallfilterpublic/saga";
import RecuiterFormSaga from "./recuiterProfileForm/saga";
import BookingSaga from "./booking/store"

export default function* rootsaga() {
    yield all([
        // fork(GetDetailSaga),
        fork(LoginRegistration),
        fork(RegistrationProfileSaga),
        fork(EducationRegSaga),
        fork(CertificateSaga),
        fork(PortFolioSaga),
        fork(RecuritLoginSaga),
        fork(FilterSaga),
        fork(RecuiterFormSaga),
        fork(BookingSaga)

    ])
}