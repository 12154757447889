export const CREATE_LOGIN = "CREATE_LOGIN";
export const CREATE_LOGIN_SUCCESS = "CREATE_LOGIN_SUCCESS";
export const CREATE_LOGIN_FAIL = "CREATE_LOGIN_FAIL";


export const OTP_VERIFY = "OTP_VERIFY";
export const OTP_VERIFY_SUCCESS = "OTP_VERIFY_SUCCESS";
export const OTP_VERIFY_FAIL = "OTP_VERIFY_FAIL";

export const RESET_LOGIN = "RESET_LOGIN";
export const RESET_OTP = "RESET_OTP";

export const REMOVE_RECRUITER_ID = "REMOVE_RECRUITER_ID";

 