import { post,get,put, remove } from "../api_helper";
 import {  FILTER,RECUITER ,GETALLRECUITER} from "../url_helper";

 
 
 
 export const getFilterApi =({state,Occupation_industry ,Occupation_skills} )=>
  get(`${FILTER}/filter?city_new=${state}&Occupation_industry=${Occupation_industry}&Occupation_skills=${Occupation_skills}`);

 export const getFilterRecuiterApi =({state,industry,skill} )=>
  get(`${RECUITER}/filter?city_new=${state}&industry=${industry}&skill=${skill}`);

  export const getAllRecuiter=()=>get(`${GETALLRECUITER}`)
 
 
 