import { POSTFREELACER,POSTRECUITER } from "./actiontype";
import { createBookingFreelacerSuccess,createBookingFreelacerFailure,
    createBookingRecuiterSuccess,createBookingRecuiterFailure
} from "./action";
import { createfreelacerapi,createrecuiterapi } from "../../_helper/services/bookingposting";
import {  takeLatest, put, call } from 'redux-saga/effects';

function* onCreateFreelancer({ payload }) {
    try {
      const response = yield call(createfreelacerapi, payload);
      yield put(createBookingFreelacerSuccess(response));
     
     
    } catch (error) {
      yield put(createBookingFreelacerFailure(error.response));
    //   toast.error('Please Provide Correct Mobile Number.', {
    //     position: 'top-right',
    //   });
    }
    //  finally{
    //   yield put(resetlogin())
    // }
  }
function* onCreateRecuiter({ payload }) {
    try {
      const response = yield call(createrecuiterapi, payload);
      yield put(createBookingRecuiterSuccess(response));
     

    } catch (error) {
      yield put(createBookingRecuiterFailure(error.response));
    //   toast.error('Please Provide Correct Mobile Number.', {
    //     position: 'top-right',
    //   });
    }
    //  finally{
    //   yield put(resetlogin())
    // }
  }

  function* CartSaga() {
    yield takeLatest(POSTFREELACER, onCreateFreelancer);
    yield takeLatest(POSTRECUITER, onCreateRecuiter);
    
  
  
  }
  
  export default CartSaga;