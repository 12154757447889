import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import rootReucer from "./reducers";
import rootReducer from "./reducers";
import rootsaga from "./sagas";

const sagaMiddilware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose


const store = createStore(
    rootReducer(),
    composeEnhancers(applyMiddleware(sagaMiddilware,thunk))
);
sagaMiddilware.run(rootsaga);

store.asyncReducers={};
export const injectReducer = (key, reducer) => {
    if (store.asyncReducers[key]) {
      return false;
    }
    store.asyncReducers[key] = reducer;
    store.replaceReducer(rootReducer(store.asyncReducers));
    return store;
  };
  
  export default store;