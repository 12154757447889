import { type } from "@testing-library/user-event/dist/type";
import { GETFILTERDATA,GETFILTERDATASUCCESS,GETFILTERDATAFAILURE,
    GETFILTERRECUITER,GETFILTERRECUITERSUCCESS,GETFILTERRECUITERFAILURE,
    GETALLRECUITER,GETALLRECUITERSUCCESS,GETALLRECUITERFAILURE,RESET
} from "./actiontype";

export const getFilterData=(state,Occupation_industry,Occupation_skills)=>{
  
    return{
        type:GETFILTERDATA,
        payload:{state,Occupation_industry,Occupation_skills}
    }
}
export const getFilterDataSuccess=(data)=>{
    return{
        type:GETFILTERDATASUCCESS,
        payload:data
    }
}
export const getFilterDataFail=(error)=>{
    return{
        type:GETFILTERDATAFAILURE,
        payload:error
    }
}
export const getFilterRecuiter=(state,industry,skill)=>{
  
    return{
        type:GETFILTERRECUITER,
        payload:{state,industry,skill}
    }
}
export const getFilterRecuiterSuccess=(data)=>{
    return{
        type:GETFILTERRECUITERSUCCESS,
        payload:data
    }
}
export const getFilterRecuiterFail=(error)=>{
    return{
        type:GETFILTERRECUITERFAILURE,
        payload:error
    }
} 
export const getAllRecuiter=()=>{
  
    return{
        type:GETALLRECUITER,
        
    }
}
export const getAllRecuiterSuccess=(data)=>{
    return{
        type:GETALLRECUITERSUCCESS,
        payload:data
    }
}
export const getAllRecuiterFail=(error)=>{
    return{
        type:GETALLRECUITERFAILURE,
        payload:error
    }
} 

export const resetFreelancerData=()=>{
    return{
        type:RESET
}
}