import {
    POSTFREELACER, POSTFREELACERSUCCESS, POSTFREELACERFAILURE,
    POSTRECUITER, POSTRECUITER_SUCCESS, POSTRECUITERFAILURE
} from "./actiontype";

const initalValues = {
    postrecuiterResults: {},
    postfreelancerResults: {},
    loading: false,
    error: {
        message: "",
    },
}

export const BookingReducers = (state = initalValues, action) => {
    if (!action) return state;
    switch (action.type) {
        case POSTFREELACER:
            state = { ...state, postfreelancerResults: {}, loading: true };
            break;
        case POSTFREELACERSUCCESS:
            state = { ...state, postfreelancerResults: action.payload, loading: false };
            break;
        case POSTFREELACERFAILURE:
            state = {
                ...state,
                error: {
                    message: "Error",
                },
                loading: false,
            };
            break;
        case POSTRECUITER:
            state = { ...state, postrecuiterResults: {}, loading: true };
            break;
        case POSTRECUITER_SUCCESS:
            state = { ...state, postrecuiterResults: action.payload, loading: false };
            break;
        case POSTRECUITERFAILURE:
            state = {
                ...state,
                postrecuiterResults: {},
                error: {

                },
                loading: false,
            };
            break;

        // case RESET_LOGIN:
        //     state = { ...state, login: {}, };
        //     break;
        // case RESET_OTP:
        //     state = { ...state, verifyOtpresult: {}, };
        //     break;
        // case REMOVE_RECRUITER_ID:
        //     localStorage.removeItem("recuiter_Id");
        //     break;
        default:
            state = { ...state };
            break
    }
    return state;
}

export default BookingReducers;