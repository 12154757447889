import axios from "axios";


const REACT_API_URL = process.env.React_APP_API_URL || "";
export const axiosApi = axios.create({
    baseURL :  "https://admin.nearbyfreelancers.com",
    // baseURL:"http://127.0.0.1:8000/"  
    // baseURL:"http://3.110.181.25",
    // baseURL:REACT_API_URL
 
});
 


export async function get(url, config) {
    return await axiosApi
      .get(url, {
        ...config,
      })
      .then((response) => response.data);
  }

// export const get = async (url, config) => {
//   try {
//     const response = await axiosApi.get(url, config);
//     return response.data;
//   } catch (error) {
//     // Handle errors
//     throw error;
//   }
// };

  export async function post(url, payload, config) {
    return await axiosApi
      .post(url, payload, {
        ...config,
      })
      .then((response) => response.data);
  }
  export async function postReturnResponse(url, payload, config) {
    // debugger;
    return await axiosApi
      .post(url, payload, {
        ...config,
      })
      .then((response) => response);
  }
  export async function put(url, payload, config) {
    return await axiosApi
      .put(url, payload, {
        ...config,
      })
      .then((response) => response.data);
  }
  export async function remove(url, payload, config) {
    return await axiosApi
      .delete(
        url,
        { data: payload },
        {
          ...config,
        }
      )
      .then((response) => response);
  }
  